import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Team.css"
import { Footer } from "../../components/Footer/Footer";
import { TopBar } from "../../components/TopBar/TopBar";

export function Team() {
    return (
        <>
            <TopBar currentPage="team" />
            <div className="container">
                <div className="row col-sm-12 my-5">
                    <center><h1>THE DIRECTOR</h1></center>
                    <div className="row col-sm-12 col-md-5" id="wilsonPic">
                        <img className="pplimg" src="/images/team/Wilson Ho.png" alt="Wison Ho" />
                    </div>
                    <div className="row col-sm-12 col-md-7">
                        <p className="m-auto text-left" id="lpara">
                            <h2>
                                Wilson Ho <br />
                                Director
                            </h2>
                            Wilson Ho is the founder of Wilson Acoustics and Acoustics Innovation. He has more than 20 years of experience in acoustics consultancy; and has built a strong reputation for integrity and high quality works.
                            <br /><br />
                            He is the inventor of Multi-Directional Tuned Mass Damper (MDTMD) and SilentUP Retractable Noise Barrier which are famous for their remarkable performance and superior quality. Wilson stresses the importance of training young Engineers and building future leaders.
                            <br /><br />
                            Please visit <a href="http://www.wal.hk">www.wal.hk</a> for more information about Wilson Acoustics.
                        </p>
                    </div>
                </div>
                <div className="row col-sm-12 my-5">
                    <center><h1>The Team</h1></center>
                    <div className="team__gallery row" id="helloxcxcx">
                        <div className="col-sm-12 col-md-6"><img src="/images/team/Wylog Wong.png" alt="Wylog" className="pplimg" /><br /><p><center><h2>Wylog Wong<br />Business Manager</h2></center></p></div>
                        <div className="col-sm-12 col-md-6"><img src="/images/team/Max Yiu.png" alt="Max" className="pplimg" /><br /><p><center><h2>Max Yiu<br />Project Manager</h2></center></p></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}
