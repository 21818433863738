import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./News.css";
import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card'
import { Footer } from "../../components/Footer/Footer";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { TopBar } from "../../components/TopBar/TopBar";

const newsCard = [
    {
        thumbnail: "/newsletter/Newsletter-01-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1501.pdf",
        name: 'WAL Newsletter 01',
        description: '8.2m High Retractable Noise Barrier'
    },
    {
        thumbnail: "/newsletter/Newsletter-02-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1502.pdf",
        name: 'WAL Newsletter 02',
        description: 'Research Project Collaboration with PolyU'
    },
    {
        thumbnail: "/newsletter/Newsletter-03-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1503.pdf",
        name: 'WAL Newsletter 03',
        description: 'Vision for Railway Noise Control'
    },
    {
        thumbnail: "/newsletter/Newsletter-04-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1504.pdf",
        name: 'WAL Newsletter 04',
        description: 'Rail Damper to be installed on 250m track, 7m High Retractable Noise Barrier'
    },
    {
        thumbnail: "/newsletter/Newsletter-05-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1505.pdf",
        name: 'WAL Newsletter 05',
        description: 'Public Seminars on SilentUp Retractable Noise Barrier'
    },
    {
        thumbnail: "/newsletter/Newsletter-06-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1506.pdf",
        name: 'WAL Newsletter 06',
        description: 'WAL 10th Anniversary Celebration'
    },
    {
        thumbnail: "/newsletter/Newsletter-07-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1507.pdf",
        name: 'WAL Newsletter 07',
        description: 'HKSTP AI Office Opening Reception'
    },
    {
        thumbnail: "/newsletter/Newsletter-08-thumbnail.png",
        file: "/newsletter/WAL_Newsletter1508.pdf",
        name: 'WAL Newsletter 08',
        description: 'WAL 10th Anniversary Celebration Dinner'
    },
    {
        thumbnail: "/newsletter/Newsletter-09-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_09.pdf",
        name: 'WAL Newsletter 09',
        description: 'Additional 250m Rail Damper at KTL and TKL'
    },
    {
        thumbnail: "/newsletter/Newsletter-10-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_10.pdf",
        name: 'WAL Newsletter 10',
        description: 'WAL pioneers QPME Consultancy Service'
    },
    {
        thumbnail: "/newsletter/Newsletter-11-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_11.pdf",
        name: 'WAL Newsletter 11',
        description: 'Railway Noise & Vibration Consultancy Service'
    },
    {
        thumbnail: "/newsletter/Newsletter-12-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_12.pdf",
        name: 'WAL Newsletter 12',
        description: 'Buy and Build Quiet Symposium'
    },
    {
        thumbnail: "/newsletter/Newsletter-13-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_13.pdf",
        name: 'WAL Newsletter 13',
        description: 'Passion, Innovation, Excellence'
    },
    {
        thumbnail: "/newsletter/Newsletter-14-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_14.pdf",
        name: 'WAL Newsletter 14',
        description: 'WAL Involvement in South Island Line'
    },
    {
        thumbnail: "/newsletter/Newsletter-15-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_15.pdf",
        name: 'WAL Newsletter 15',
        description: 'QPME - New Application and Renewal'
    },
    {
        thumbnail: "/newsletter/Newsletter-16-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_16.pdf",
        name: 'WAL Newsletter 16',
        description: 'Q-Rail Damper Trial in London'
    },
    {
        thumbnail: "/newsletter/Newsletter-17-thumbnail.png",
        file: "/newsletter/WAL_Newsletter_17.pdf",
        name: 'WAL Newsletter 17',
        description: '6th Generation Rail Damper'
    },
].reverse()



export function News() {
    // let mysql = require('mysql');
    const [email, setEmail] = useState("");
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    function validateForm() {
        return email.length > 0;
    }
    function subNews(event) {
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            body: JSON.stringify({ 'email': email })
        }
        fetch("/php/newsletter.php", requestOptions)
            .then(res => res.json())
            .then(res => console.log(res))

    }
    return (
        <>
            <TopBar currentPage="news" />
            <div className="container py-5">
                <div className="col-sm-12 py-3">
                    <center><h1>Newspaper Articles</h1></center>
                    <p id="newsIntro" className={isMobile ? "py-4" : "py-4 px-5"}>We are honoured for having been interviewed by a local newspaper Ming Pao (明報). An article on our SilentUP<sup>®</sup> Barrier was published on 31 July 2015, titled</p>
                    <br />
                    <h2 id="newsTitle" >港產隔音屏障 提升工程效率</h2>
                    <br />

                    <Table responsive borderless>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="newsTitle2" >港產隔音屏障簡介片
                                    <br />
                                    SilentUP<sup>®</sup> noise barrier introduction video
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/tFc8ax55a74" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                            </td>
                            <td className="text-end">
                                <a href="/newsletter/Acoustics_Innovation_Ming_Pao_2015-07-31.pdf" >
                                    <img src="/newsletter/MingPao20150731-thumbnail.jpg" style={{ "border": "#000 1px solid" }} alt="newpaper" title="Click to view the news" id="newsImg" />
                                </a> <br />

                                {/* <p id="newsLink" align="right" width="100%">
                                </p> */}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="text-end" id="newsLink">
                                Full article: [
                                <a href="http://news.mingpao.com/pns/%E6%B8%AF%E7%94%A2%E9%9A%94%E9%9F%B3%E5%B1%8F%E9%9A%9C%20%E6%8F%90%E5%8D%87%E5%B7%A5%E7%A8%8B%E6%95%88%E7%8E%87%20%E6%98%93%E7%B5%84%E8%A3%9D%20%20%E5%B0%8F%E5%9E%8B%E5%A0%B4%E5%9C%B0%E4%B9%9F%E5%90%88%E7%94%A8/web_tc/article/20150731/s00004/1438278951901;TopicID=ba01&amp;Filename=baa1.txt" >Ming Pao</a>
                                ] [
                                <a href="/newsletter/Acoustics_Innovation_Ming_Pao_2015-07-31.pdf">PDF</a>
                                ]
                            </td>
                        </tr>
                    </Table>
                </div >
                <div className="col-sm-12">
                    <center><h1>Newsletter</h1></center>
                    <p id="newsIntro" className="my-3 py-4">Wilson Acoustics (WAL) and Acoustics Innovation (AI) jointly publish newsletter to update our clients. You can find all the latest products, accomplishments, training sessions, etc. If you would like to receive our newsletters, please submit your email below. We do not spam, and keep your information confidential.</p>
                    <Row sm={1} md={2} lg={3} className="g-4">
                        {
                            newsCard.map((newInfo, ind) => (
                                <Col>
                                    <Card style={{ width: '18rem' }} bg="white" className="mx-auto border-0">
                                        <a href={newInfo.file} id="cardLink">
                                            <Card.Img variant="top" src={newInfo.thumbnail} style={{ height: '100%' }} />
                                        </a>
                                        <Card.Body id="kxtry">
                                            <div id="noOp">
                                                <Card.Title>{newInfo.name}</Card.Title>
                                                <Card.Text>{newInfo.description}</Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>

                                </Col>
                            ))
                        }
                    </Row>
                </div>
                <div className="col-sm-12 py-4">
                    <center><h1>EVENT</h1></center>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 col-md-8">
                            <center><img src="/newsletter/AIHK_Opening.jpg" alt=" reception" /></center>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <h2 id="openingRecep"> AI Opening Reception</h2>
                            <br /> <br />
                            <p id="openingPara">The Acoustics Innovation opening reception was held on 6th August successfully. There were more than 100 honourable guests with us. We had demonstion and presentation about our company which let our guests to have better understanding of us. </p>
                        </div>
                    </div>
                </div>
            </div >
            <div className="full-width py-3 px-md-5 my-0 " id="subscribeYeah">
                <center><h1>Subscribe to Newsletter</h1></center>
                <center className="px-5">
                    <b>Subscribe to our newsletter. We keep your contact details confidential.</b>
                    <br /><br />
                    <Form onSubmit={subNews}>
                        <Form.Group as={Row} className="mb-3" controlId="formEmail">
                            <Form.Label column sm={2}>Email</Form.Label>
                            <Col sm={6}><Form.Control type="email" value={email} placeholder="joe.bloggs@email.com" onChange={(e) => { setEmail(e.target.value); }} /></Col>
                            <Col sm={4}>
                                <Button block type="submit" disabled={!validateForm()}>Subscribe</Button>
                            </Col>
                        </Form.Group>
                        {/* <Form.Group as={Row} className="mb-3 d-flex justify-content-between">
                            
                        </Form.Group> */}
                    </Form>
                </center>
            </div>
            <Footer />
        </>

    )
}