import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useRef, useState, useEffect } from "react";
import "./Footer.css"

export function Footer() {
    // const isMobile = useMediaQuery({ query: `(max-width: 850px)` });
    // const isPad = useMediaQuery({ query: `(max-width: 1000px)` });
    // const hideLoginText = 690
    // const hideMenu = 747
    // const hideTitle = 430

    const fb = "/images/fb.png";
    const lin = "/images/in.png";
    const wal = "/images/wal.png";
    const yt = "/images/yt.png";
    const fb_hover = "/images/fb_hover.png";
    const lin_hover = "/images/in_hover.png";
    const wal_hover = "/images/wal_hover.png";
    const yt_hover = "/images/yt_hover.png";
    const [fbimg, setfbimg] = useState(fb);
    const [inimg, setinimg] = useState(lin);
    const [walimg, setwalimg] = useState(wal);
    const [ytimg, setytimg] = useState(yt);

    return (
        <section className="footer px-3  bottom-0" id="footernav">
            {/* <section
                className="p-4"
            > */}
            {/* <div className="me-5 d-none d-lg-block text-light align-items-center">
                    <span>Get connected with us on social networks:</span>
                </div> */}
            {/* <div className="container AIfooter">
                    <div className="row"> */}
            <div className="d-flex justify-content-end AIfooter">
                <div className="p-3">
                    <a href="https://www.facebook.com/people/Acoustics-Innovation-Limited/100067157670344/" className="me-4 text-reset">
                        <img src={fbimg} alt="facebook" onMouseOver={() => setfbimg(fb_hover)} onMouseOut={() => setfbimg(fb)} />
                    </a>
                </div>
                <div className="p-3">
                    <a href="https://www.linkedin.com/company/acoustics-innovation-limited/" className="me-4 text-reset">
                        <img src={inimg} alt="linkedin" onMouseOver={() => setinimg(lin_hover)} onMouseOut={() => setinimg(lin)} />
                    </a>
                </div>
                <div className="p-3">
                    <a href="https://www.youtube.com/channel/UC22DgQzxDnLUuSLl0Kjk1RQ" className="me-4 text-reset">
                        <img src={ytimg} alt="youtube" onMouseOver={() => setytimg(yt_hover)} onMouseOut={() => setytimg(yt)} />
                    </a>
                </div>
                <div className="p-3">
                    <a href="http://www.wal.hk/" className="me-4 text-reset">
                        <img src={walimg} alt="website" onMouseOver={() => setwalimg(wal_hover)} onMouseOut={() => setwalimg(wal)} />
                    </a>
                </div>

            </div>
            {/* <Nav.Item className="ms-auto">

            </Nav.Item>
            <Nav.Item className="ms-auto">

            </Nav.Item> */}
            {/* </div>
                </div> */}
            {/* </section> */}
        </section >
    )



}