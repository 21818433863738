import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Sunglasses, Headphones, BriefcaseFill, PeopleFill, Globe, MegaphoneFill } from "react-bootstrap-icons";
import { useMediaQuery } from 'react-responsive'
import "./TopBar.css"

export function TopBar({ currentPage }) {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
    // const isMedium = useMediaQuery({ query: `(min-width: 850px)` });
    // const hideLoginText = 690
    // const hideMenu = 747
    // const hideTitle = 430

    const [barWidth, setBarWidth] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const ref1 = useRef(null);


    const getWidth = () => {
        ref1.current && setBarWidth(ref1.current.offsetWidth);
    };

    useEffect(() => {
        getWidth();
    }, [barWidth]);

    return (
        <>{
            isMobile ?
                <>
                    <Navbar expanded={expanded} collapseOnSelect expand="lg" bg="custom" sticky="top" className="topbar m-0 p-0" ref={ref1}>
                        <Navbar.Brand href="#" id="biglogo" onClick={() => navigate("/")}>
                            <img src="/images/biglogo.png" style={{ "width": "60vw" }} alt="loading..." draggable="false" />
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link className="link px-3" onClick={() => { navigate("/about"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><Sunglasses /> Who We are</h5></Nav.Link>
                                <Nav.Link className="link px-3" onClick={() => { navigate("/products"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><Headphones /> Products</h5></Nav.Link>
                                <Nav.Link className="link px-3" onClick={() => { navigate("/projects"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><BriefcaseFill /> Projects</h5></Nav.Link>
                                <Nav.Link className="link px-3" onClick={() => { navigate("/team"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><PeopleFill /> Team</h5></Nav.Link>
                                <Nav.Link className="link px-3" onClick={() => { navigate("/news"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><Globe /> News</h5></Nav.Link>
                                <Nav.Link className="link px-3" onClick={() => { navigate("/recruitment"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5><MegaphoneFill /> Recruitment</h5></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar >
                </>
                :
                <>
                    <Navbar expanded={expanded} style={{ "height": "80px", "background-color": "#C0C0C0" }} collapseOnSelect expand="lg" sticky="top" className="topbar m-0 p-0" ref={ref1}>
                        <Navbar.Brand href="#" id="biglogo" onClick={() => navigate("/")}>
                            <img src="/images/biglogo.png" style={{ "height": "110px" }} alt="loading..." draggable="false" />
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="flex-grow-1 justify-content-evenly align-items-center">
                                <div className={currentPage === "about" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "about" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/about"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>Who We Are</h5></Nav.Link>
                                <div className={currentPage === "about" || currentPage === "products" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "products" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/products"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>Products</h5></Nav.Link>
                                <div className={currentPage === "projects" || currentPage === "products" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "projects" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/projects"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>Projects</h5></Nav.Link>
                                <div className={currentPage === "projects" || currentPage === "team" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "team" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/team"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>Team</h5></Nav.Link>
                                <div className={currentPage === "team" || currentPage === "news" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "news" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/news"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>News</h5></Nav.Link>
                                <div className={currentPage === "recruitment" || currentPage === "news" ? "vertical-row active-topbar" : "vertical-row"} />
                                <Nav.Link className={currentPage === "recruitment" ? "link px-3 active-topbar" : "link px-3"} onClick={() => { navigate("/recruitment"); if (isMobile) { setExpanded(expanded ? false : "expanded"); } }}><h5>Recruitment</h5></Nav.Link>
                                <div className={currentPage === "recruitment" ? "vertical-row-end active-topbar" : "vertical-row-end"} />
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar >
                </>
        }
        </>


    )



}