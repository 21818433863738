import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Projects.css";
import { TopBar } from "../../components/TopBar/TopBar";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from "react-bootstrap/Nav";
import Papa from 'papaparse';
import ImageGallery from 'react-image-gallery';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Footer } from "../../components/Footer/Footer";


export function Projects() {

    function _renderImage(project) {
        return () => (
            <>
                <div style={{ 'position': 'relative' }}>
                    <img className="image-gallery-image project-slideshow" src={project.image} alt="project images" />
                    <span className="image-gallery-description description-slideshow"><h2>{project.Title}</h2>Project Type: {project["Project Type"]} | Year: {project.Year} | Contractor: {project.Contractor} </span>
                </div>
                <div className="py-2 long-description-slideshow"> <p>{project.Description} </p></div>
            </>
        )
    }

    function createImageContent() {
        let content = []
        if (filter1 === "0") {
            // setFilter2("0");
            // Add all images
            for (const key in projects) {
                for (let i = 0; i < projects[key].length; i++) {
                    if (filter2 === "0" || projects[key][i][filter2_mapping[filter2]] === '1') {
                        content.push({
                            original: projects[key][i].image,
                            thumbnail: projects[key][i].image,
                            description: projects[key][i].Description,
                            renderItem: _renderImage(projects[key][i])
                        })
                    }
                }
            }

        }
        else {
            for (let i = 0; i < projects[filter1].length; i++) {
                if (filter2 === "0" || projects[filter1][i][filter2_mapping[filter2]] === '1') {
                    content.push({
                        original: projects[filter1][i].image,
                        thumbnail: projects[filter1][i].image,
                        description: projects[filter1][i].Description,
                        renderItem: _renderImage(projects[filter1][i])
                    })
                }
            }
        }
        setImages(content);
    }
    const [filter1, setFilter1] = useState("0"); // Four project type filters, 0 means no filter
    const [filter2, setFilter2] = useState("0"); //Seven construction type filters, 0 means no filter
    const [projects, setProjects] = useState({});
    const [images, setImages] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const filter2_mapping = {
        "0": "All",
        "1": "A&A",
        "2": "Civil",
        "3": "Foundation",
        "4": "Housing",
        "5": "Highway",
        "6": "Tunnel",
        "7": "Utilities"
    };
    // const filter2_inverse_mapping = {
    //     "A&A": "1",
    //     "Civil": "2",
    //     "Foundation": "3",
    //     "Housing": "4",
    //     "Highway": "5",
    //     "Tunnel": "6",
    //     "Utilities": 7
    // }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        createImageContent();
    }, [filter1, filter2, projects]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        async function getData() {
            const res = await fetch("/projects.csv")
            const reader = res.body.getReader()
            const result = await reader.read()
            const decoder = new TextDecoder('utf-8')
            const csv = decoder.decode(result.value)
            const results = Papa.parse(csv, { header: true })
            let all_projects = { "1": [], "2": [], "3": [], "4": [] };
            for (let i = 0; i < results.data.length; i++) {
                let j = results.data[i].Type.toString()
                if (j in all_projects) {
                    all_projects[j].push(results.data[i])
                }
            }
            setProjects(all_projects);
        }
        setFilter1("0");
        getData();

    }, []);

    const isMobile = width <= 1036;

    const handleSelect = (e) => {
        setFilter2(e);
        console.log(e);
    }

    return (
        <>
            <TopBar currentPage="projects" />
            {isMobile ?
                <ImageGallery items={images} lazyLoad={true} showThumbnails={false} showFullscreenButton={false} showPlayButton={false} />
                :
                <ImageGallery items={images} lazyLoad={true} thumbnailPosition="right" showFullscreenButton={false} showPlayButton={false} />
            }

            {!isMobile ?
                <Container className="my-2">
                    <Navbar expanded="expanded" variant="light" bg="white">
                        <Container>
                            <Nav className="flex-grow-1 justify-content-evenly align-items-center">
                                <div className="vertical-row-norm" />
                                <Nav.Link className={filter1 === "0" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("0") }}><h5>All</h5></Nav.Link>
                                <div className="vertical-row-norm" />
                                <Nav.Link className={filter1 === "1" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("1") }}><h5>Government department</h5></Nav.Link>
                                <div className="vertical-row-norm" />
                                <Nav.Link className={filter1 === "2" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("2") }}><h5>Government related organization</h5></Nav.Link>
                                <div className="vertical-row-norm" />
                                <Nav.Link className={filter1 === "3" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("3") }}><h5>Public Utility</h5></Nav.Link>
                                <div className="vertical-row-norm" />
                                <Nav.Link className={filter1 === "4" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("4") }}><h5>Private sector</h5></Nav.Link>
                                <div className="vertical-row-norm" />
                            </Nav>
                        </Container>
                    </Navbar>
                </Container>
                :
                <>
                    <Container className="my-1">
                        <Navbar expanded="expanded" variant="light" bg="white">
                            <Container>
                                <Nav className="flex-grow-1 justify-content-evenly align-items-center">
                                    <div className="vertical-row-norm" />
                                    <Nav.Link className={filter1 === "0" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("0") }}><h5>All</h5></Nav.Link>
                                    <div className="vertical-row-norm" />
                                    <Nav.Link className={filter1 === "1" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("1") }}><h5>Government department</h5></Nav.Link>
                                    <div className="vertical-row-norm" />
                                </Nav>
                            </Container>
                        </Navbar>
                    </Container >
                    <Container className="my-1">
                        <Navbar expanded="expanded" variant="light" bg="white">
                            <Container>
                                <Nav className="flex-grow-1 justify-content-evenly align-items-center">
                                    <div className="vertical-row-norm" />
                                    <Nav.Link className={filter1 === "2" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("2") }}><h5>Government related organization</h5></Nav.Link>
                                    <div className="vertical-row-norm" />
                                </Nav>
                            </Container>
                        </Navbar>
                    </Container >
                    <Container className="my-1">
                        <Navbar expanded="expanded" variant="light" bg="white">
                            <Container>
                                <Nav className="flex-grow-1 justify-content-evenly align-items-center">
                                    <div className="vertical-row-norm" />
                                    <Nav.Link className={filter1 === "3" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("3") }}><h5>Public Utility</h5></Nav.Link>
                                    <div className="vertical-row-norm" />
                                    <Nav.Link className={filter1 === "4" ? "link-norm active-norm px-3" : "link-norm px-3"} onClick={() => { setFilter1("4") }}><h5>Private sector</h5></Nav.Link>
                                    <div className="vertical-row-norm" />
                                </Nav>
                            </Container>
                        </Navbar>
                    </Container ></>
            }
            <Container className="my-2">
                <div className="row">
                    <div className="col-sm-12 col-md-8 ml-auto" id="firstField">
                        <h5>Construction Work Type</h5>
                    </div>
                    <div className="col-sm-12 col-md-4" >
                        <DropdownButton title={filter2_mapping[filter2]} variant="success" onSelect={handleSelect}>
                            <Dropdown.Item eventKey="0">All</Dropdown.Item>
                            <Dropdown.Item eventKey="1">A&A work</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Civil work</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Foundation</Dropdown.Item>
                            <Dropdown.Item eventKey="4">Housing</Dropdown.Item>
                            <Dropdown.Item eventKey="5">Highway</Dropdown.Item>
                            <Dropdown.Item eventKey="6">Tunnel</Dropdown.Item>
                            <Dropdown.Item eventKey="7">Utilities</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
            </Container>
            {/* <Footer /> */}
        </>

    )
}