import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Recruitment.css";
import Table from 'react-bootstrap/Table'
import { Footer } from "../../components/Footer/Footer";
import { TopBar } from "../../components/TopBar/TopBar";

const jobs = {
    "Entry Level": [
        {
            title: "Graduate Engineer",
            table_fields: {
                "Employ Mode": "Full Time Job",
                "Position Offered": "Graduate Engineer / Design Engineer (R&D Team)",
                "Vacancies": 1,
                "Working Experience": "(nil)",
                "Major Place of Work": "Hong Kong -New Territories",
            },
            requirement_fields: {
                "Responsibilities": [
                    "Work as part of a team to research and design new acoustic products;",
                    "Communicate with suppliers and purchase materials;",
                    "Assist in construction of acoustic products."
                ],
                "Requirements": [
                    "Enthusiasm for developing profession in acoustics;",
                    "Degree holder or above of Engineering, Science or relative discipline;",
                    "First class honour is necessary, post-graduate student is also welcome;",
                    "Sound knowledge in material such as fiber glass, metal, rubber etc;",
                    "Innovative, self-motivated and outgoing;",
                    "Strong analytical skills; (preferred but not necessary)",
                    "Good in both spoken and written Chinese and English. Fair spoken Putonghua; (preferred but not necessary)",
                    "Basic command in Microsoft Office e.g. Excel, Word and PowerPoint;",
                    "Familiar with construction site operation;",
                    "Occasional overnight work may be required."
                ],
                "Other Benefits": [
                    "Vast learning and personal development opportunities;",
                    "Salary review after 3 month probation and every 6 months afterwards;",
                    "Bonus every 6 months;",
                    "Overnight and holiday work compensation;",
                    "Medical Insurance after 3 month probation."
                ]
            }
        },
        {
            title: "Technician Trainee",
            table_fields: {
                "Employ Mode": "Full Time Job",
                "Position Offered": "Technician Trainee",
                "Vacancies": 1,
                "Working Experience": "(nil)",
                "Major Place of Work": "Hong Kong -New Territories",
            },
            requirement_fields: {
                "Responsibilities": [
                    "Work as part of a team to research and design new acoustic products;",
                    "Communicate with suppliers and purchase materials;",
                    "Possess good knowledge of Chinese typing;",
                    "Assist in construction of acoustic products."
                ],
                "Requirements": [
                    "Enthusiasm for developing profession in acoustics;",
                    "Engineering or science discipline (Diploma holder or above);",
                    "Innovative, self-motivated and outgoing.",
                ],
                "Other Benefits": [
                    "Vast learning and personal development opportunities;",
                    "Salary review after 3 month probation and every 6 months afterwards;",
                    "Bonus every 6 months;",
                    "Overnight and holiday work compensation;",
                    "Medical Insurance after 3 month probation."
                ]
            }
        }
    ],
    "Experienced Positions": [
        {
            title: "Senior Project Engineer",
            table_fields: {
                "Employ Mode": "Full Time Job",
                "Position Offered": "Project Engineer",
                "Vacancies": 1,
                "Working Experience": "4 years",
                "Major Place of Work": "Hong Kong -New Territories",
            },
            requirement_fields: {
                "Responsibilities": [
                    "Coordinate the different stages of the product development;",
                    "Responsible for projects handling, quality and time management;",
                    "With experience in project management will be an advantage;",
                    "Communicate with contractors and customers;",
                    "Develop and implement strategic procedures to achieve Company goal;"
                ],
                "Requirements": [
                    "Degree holder or above of Engineering, Science or relative discipline;",
                    "4 years engineering/product research and design experience;",
                    "Good problem-solving and presentation skill;",
                    "Influential leadership, collaborative and result oriented style.",
                    "Good in both spoken and written Chinese and English. Fair spoken Putonghua;",
                    "Familiar with construction site operation;",
                    "Occasional overnight work may be required."
                ],
                "Other Benefits": [
                    "Vast learning and personal development opportunities;",
                    "Salary review after 3 month probation and every 6 months afterwards;",
                    "Bonus every 6 months;",
                    "Overnight and holiday work compensation;",
                    "Medical Insurance after 3 month probation."
                ]
            }
        },
        {
            title: "Senior Technician",
            table_fields: {
                "Employ Mode": "Full Time Job",
                "Position Offered": "Engineering Assistant / Senior Technician",
                "Vacancies": 1,
                "Working Experience": "5 years",
                "Major Place of Work": "Shatin",
            },
            requirement_fields: {
                "Responsibilities": [
                    "Assist in researching and designing new acoustic products noise ;",
                    "Communicate with suppliers and purchase materials;",
                    "Possess good knowledge of Chinese typing;",
                    "Handle construction of acoustic products.",
                ],
                "Requirements": [
                    "Higher Diploma or above, or equivalent, in a relevant science or engineering subject;",
                    "At least 5 years full time working experience;",
                    "Sound knowledge in material such as fiber glass, metal, rubber etc;",
                    "Innovative, self-motivated and outgoing.",
                    "Sound knowledge in acoustic/noise;",
                    "Good in both spoken and written Chinese and English. Fair spoken Putonghua;",
                    "Basic command in Microsoft Office e.g. Excel, Word and PowerPoint;",
                    "Familiar with construction site operation;",
                    "Occasional overnight work may be required.",
                ],
                "Other Benefits": [
                    "Vast learning and personal development opportunities;",
                    "Salary review after 3 month probation and every 6 months afterwards;",
                    "Bonus every 6 months;",
                    "Overnight and holiday work compensation;",
                    "Medical Insurance after 3 month probation."
                ]
            }
        },
        {
            title: "設計工程師",
            table_fields: {
                "聘用模式": "全職",
                "招聘職位": "設計工程師",
                "空缺": 1,
                "工作經驗": "2至3年或以上",
                "主要工作地點": "沙田",
            },
            requirement_fields: {
                "職責": [
                    "了解小型五金製品設計與生產工差了解工程設計(具橡膠生產經驗優先);",
                    "具最少2至3 年小型金屬設計，如激光切割、線切割、五金衝壓等生產工藝經驗;",
                    "與金屬廠家(內地材料供應商)溝通及採購;",
                    "規管產品品質的流程及規格，包括制定公差要求;",
                    "需不定期到內地金屬廠家見面或監工(以一年平均計算，每月少於兩天)。",
                ],
                "能力需求": [
                    "具最少2至3年小型金屬製品的品質管理經驗;",
                    "圖紙閱讀;",
                    "工場安全及物資管理;",
                    "以中文(普通話)與內地材料商溝通。",
                ],
                "其他福利": [
                    "三個月試用期後有薪酬調整;",
                    "不定期發放佣金及花紅;",
                    "超時工作或假期工作薪金;",
                    "醫療保險(試用期後)。",
                ]
            }
        }
    ]
}

export function Recruitment() {
    const spanGE = (kk) => {
        let x = document.getElementById(kk);
        let y = document.getElementById(kk + "span");
        if (x.style.display === "none") {
            x.style.display = "block";
            y.innerText = "(show less)";
        } else {
            x.style.display = "none";
            y.innerText = "(show more)";
        }
    }


    return (
        <>
            <TopBar currentPage="recruitment" />
            <div className="container" id="recruitContainer">
                <div className="row">
                    <div className="col-sm-12 col-md-4 my-5" id="recruitTitle">
                        <center><h2 ID="recruitment">RECRUITMENT</h2></center>
                    </div>
                    <div className="col-sm-12 col-md-8 my-5" id="recruitText">
                        <p>
                            Interested parties please send a covering letter and a resume of no more than 3 pages, with exam results to
                            <a href="mailto:recruitment@aihk.hk"> recruitment@aihk.hk</a>
                        </p>
                    </div>
                    {
                        Object.keys(jobs).map((key) => (
                            <>
                                <div className="col-sm-12 recruitCard mx-4" >
                                    <center><h3>{key}</h3></center>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                jobs[key].map((job, ind) => (
                                                    <div className="my-3 col-sm-12 col-md-6 hihi">
                                                        <h4 className="titleRecruit" onClick={() => spanGE(key.split(' ').join('') + ind.toString())}>{job.title} <span className="smallerSpan" id={key.split(' ').join('') + ind.toString() + "span"}>(Show more)</span></h4>
                                                        <Table responsive borderless>
                                                            {
                                                                Object.keys(job.table_fields).map((key2) => (
                                                                    <tr><td>{key2}</td><td>{job.table_fields[key2]}</td></tr>
                                                                ))
                                                            }
                                                        </Table>
                                                        <div id={key.split(' ').join('') + ind.toString()} style={{ "display": "none" }}>
                                                            {
                                                                Object.keys(job.requirement_fields).map((key3) => (
                                                                    <>
                                                                        <h5>{key3}</h5>
                                                                        <ul>
                                                                            {
                                                                                job.requirement_fields[key3].map((req, ind2) => (
                                                                                    <li>{req}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                </div>
                            </>
                        ))
                    }
                    <header>
                        <center><h3>Staff Exchange</h3></center>
                    </header>
                    <div className="col-sm-12 col-md-5 py-4">
                        <center><img src="/images/exchange.png" alt="exchange" id="exchangePhoto" /></center>
                    </div>
                    <div className="col-sm-12 col-md-7 py-4" id="exchangeText">
                        <p>
                            Staff exchange activities are a great way to enhance the professional experience of staff. Our most recent exchange programme was completed by the Senior Project Engineer, Wylog Wong, who went to Sustainable Acoustics in the UK.
                            <br /><br />
                            We are actively seeking more exchange opportunities. If you or your company staff are interested to work in Hong Kong for a few months, we would be glad to discuss the details. Please send us an email and indicate your interest!
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}