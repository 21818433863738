import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Products.css";
import { TopBar } from "../../components/TopBar/TopBar";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from 'react-bootstrap/Card'
import { Footer } from "../../components/Footer/Footer";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const productList = [
    {
        name: "SilentUP®",
        description: "SilentUP® is a patented retractable noise barrier for construction works and outdoor music events. It can be easily installed and mobilized by people without using any machines. No concrete foundation is required and the installation process is quiet enough to be conducted even at night time. The panels are installed upwards from ground level and connected by magnetic gap sealing.  SilentUP® retractable noise barrier have already been implemented up to 7m high in Hong Kong major construction site (Railway construction projects of MTRC, public housing construction of HKHA, roadworks of WSD). ",
        img: "/products/SilentUP.jpg",
        attachments: [
            {
                name: "SilentUP Noise Barrier Catalogue",
                file: "/products/SilentUP-Catalogue.pdf"
            },
            {
                name: "SilentUP Job Reference",
                file: "/products/SilentUP_Job_References.pdf"
            }

        ]
    },
    {
        name: "SilentCube",
        description: "SilentCUBE is a retractable noise enclosure for road works, which provides 18 dB(A) white noise insertion loss and is therefore suitable for Prescribed Construction Work (PCW). SilentCube of 2.5m(H)x2.5m(W)x2.5m(L) is robust and can be easily transported by a trolley and installed within 15 mins by 4 people. All components are <15kg and the overall weight is <100kg, hence it can be conveniently relocated. SilentCube can be connected with another SilentCube without gaps to extend enclosure length.",
        img: "/products/SilentCUBE.jpg",
        attachments: [
            {
                name: "SilentCUBE Noise Enclosure Catalogue",
                file: "products/SilentCUBE-Catalogue.pdf"
            },
            {
                name: "SilentCUBE Job Reference",
                file: "/products/SilentCUBE_Job_References.pdf"
            }
        ]
    }
]

export function Products() {
    const [height, setHeight] = useState(1);
    const [width, setWidth] = useState(1.35);
    const [price, setPrice] = useState(1800);
    const [calType, setCalType] = useState(0); // 0 for slientUp, 1 for slientCube
    const [modelType, setModelType] = useState(0); // 0 for STC18 , 1 for STC24
    const [cubeInd, setCubeInd] = useState(-1); // 0 for $28000, 1 for $30000, 2 for $32000
    const [quant, setQuant] = useState(1);
    const [ws, setWs] = useState("")
    const [size, setSize] = useState("Select SilentCUBE size")

    useEffect(() => { //reset things to default
        setSize("Select SilentCUBE size");
        setWs("");
        setQuant(1);
        setModelType(0);
        setWidth(1.35);
        setHeight(1);
        setCubeInd(-1);
    }, [calType])

    useEffect(() => { //define calculator behavior here
        if (calType === 0) { //slientUp
            if (modelType === 0) // STC18
                setPrice(Number((height * width * 1800).toFixed(2)));
            else //STC24
                setPrice(Number((height * width * 2200).toFixed(2)));
        }
        else { //silentCube
            if (cubeInd === -1)
                setPrice(0);
            else if (cubeInd === 0)
                setPrice(28000 * quant);
            else if (cubeInd === 1)
                setPrice(30000 * quant);
            else if (cubeInd === 2)
                setPrice(32000 * quant);

        }

    }, [height, width, quant, cubeInd, calType, modelType]);



    const handleSelect = (e) => {
        let selSize = e.slice(0, -1);
        let selInd = Number(e.slice(-1));
        setSize(selSize);
        setCubeInd(selInd);
        if (selInd === 0)
            setWs("1.3m (L) x 1.3m (W) x 1.8m (H)");
        else if (selInd === 1)
            setWs("1.8m (L) x 1.8m (W) x 1.8m (H)");
        else
            setWs("2.3m (L) x 2.3m (W) x 1.8m (H)");
    }

    return (
        <>
            <TopBar currentPage="products" />
            <div className="container my-5" id="productContainer">
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <center><h2 ID="product">Products</h2></center>
                    </div>
                    <div className="col-sm-12 col-md-8" id="productText">
                        <p>The SilentUP<sup>®</sup> Retractable Noise Barrier is designed to be easily installed and mobilized by people without using any mechanical equipment. It is installed upwards from ground level and the process is quiet for nighttime assembly. An automatic mechanism is incorporated to relieve excessive wind load on structure during occasional gusts in order to achieve the lightweight structure. Water barriers with extended supports are used as the base to ensure structural stability.</p>	<br />
                    </div>
                </div>
                <Row sm={1} md={2} className="g-4">
                    {
                        productList.map((prod, ind) => (
                            <Col className="my-5">
                                <Card style={{ width: '85%', height: '100%' }} bg="white" className="mx-auto border-0">
                                    <Card.Img variant="top" src={prod.img} style={{ width: '100%' }} />
                                    <Card.Body id="kxtry2">
                                        <div id="noOp">
                                            <Card.Title>{prod.name}</Card.Title>
                                            <Card.Text><p id={"text" + ind.toString()}>{prod.description}</p></Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {
                                    prod.attachments.map((att, i) => (
                                        <div className="prod-attachments my-1">
                                            <a href={att.file}>{att.name}</a>
                                        </div>
                                    ))
                                }
                            </Col>
                        ))
                    }
                </Row>

                <div className="row my-5">
                    <center><h1>Calculator</h1></center>
                    <div className="row my-3">
                        <div className={calType === 0 ? "col-sm-6 title-select activeTab" : "col-sm-6 title-select inactiveTab"} onClick={() => setCalType(0)}><div className="prod-title">SilentUP</div> retractable noise barrier</div>
                        <div className={calType === 0 ? "col-sm-6 title-select inactiveTab" : "col-sm-6 title-select activeTab"} onClick={() => setCalType(1)}><div className="prod-title">SlientCUBE</div> retractable noise enclosure</div>
                        <div className="col-sm-12" id="calborder">
                            {calType === 0 ?
                                <div className="my-4 mx-5" id="calculator">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <h5>Height</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4" id="secondBorder">
                                            <p>{height}<span className="cal-unit">/m</span></p>
                                        </div>
                                        <div className="col-sm-12 my-3" id="slider">
                                            <Slider trackStyle={{ backgroundColor: '#00A451' }} handleStyle={{ borderColor: "#00A451", backgroundColor: '#00A451', }} min={1} max={7} step={1} onChange={(val) => setHeight(val)} />
                                        </div>
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <h5>Width</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4" id="secondBorder">
                                            <p>{width}<span className="cal-unit">/m</span></p>
                                        </div>
                                        <div className="col-sm-12 my-3 " id="slider">
                                            <Slider trackStyle={{ backgroundColor: '#00A451' }} handleStyle={{ borderColor: "#00A451", backgroundColor: '#00A451', }} min={1.35} max={99.9} step={1.35} onChange={(val) => setWidth(val)} />
                                        </div>
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <h5>Model</h5>
                                            <div className="radio-btn-container">
                                                <div className="radio-btn-first" onClick={() => setModelType(0)}>
                                                    <input type="radio" value={modelType} name="modelType" checked={modelType === 0} /> STC18 (HK$1,800/sq.m)
                                                </div>
                                                <div className="radio-btn" onClick={() => setModelType(1)}>
                                                    <input type="radio" value={modelType} name="modelType" checked={modelType === 1} /> STC24 (HK$2,200/sq.m)
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-6 col-md-4 final-price" id="secondBorder">
                                            <p>HK$ <span className="cal-unit">{price}</span></p>
                                        </div>
                                    </div>
                                </div> :
                                <div className="my-4 mx-5" id="calculator">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-8 ml-auto" id="firstField">
                                            <h5>Size</h5>
                                        </div>
                                        <div className="col-sm-12 col-md-4" >
                                            <DropdownButton title={size} variant="success" onSelect={handleSelect}>
                                                <Dropdown.Item eventKey="1.5m (L) x 1.5m (W) x 2.2m (H)0">1.5m (L) x 1.5m (W) x 2.2m (H)</Dropdown.Item>
                                                <Dropdown.Item eventKey="2.0m (L) x 2.0m (W) x 2.2m (H)1">2.0m (L) x 2.0m (W) x 2.2m (H)</Dropdown.Item>
                                                <Dropdown.Item eventKey="2.5m (L) x 2.5m (W) x 2.2m (H)2">2.5m (L) x 2.5m (W) x 2.2m (H)</Dropdown.Item>
                                            </DropdownButton>
                                        </div>
                                        <div className="col-sm-12 my-3" >
                                        </div>
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <h5>Working Space</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4" id="secondBorder">
                                            {ws}
                                        </div>
                                        <div className="col-sm-12 my-3" >
                                        </div>
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <h5>Quantity</h5>
                                        </div>
                                        <div className="col-sm-6 col-md-4" id="secondBorder">
                                            {quant}
                                        </div>
                                        <div className="col-sm-12 my-3 " id="slider">
                                            <Slider trackStyle={{ backgroundColor: '#00A451' }} handleStyle={{ borderColor: "#00A451", backgroundColor: '#00A451', }} min={1} max={10} step={1} onChange={(val) => setQuant(val)} />
                                        </div>
                                        <div className="col-sm-6 col-md-8 ml-auto" id="firstField">
                                            <div className="my-2" id="specialPlaceholder"></div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 final-price" id="secondBorder">
                                            <p>HK$ <span className="cal-unit">{price}</span></p>
                                        </div>
                                    </div>
                                </div>

                            }

                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}