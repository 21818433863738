import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Footer } from "../../components/Footer/Footer";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from "react-slideshow-image";
import "./Home.css";
import { TopBar } from "../../components/TopBar/TopBar";
import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: "/images/home_slideshow/1.jpg"
    },
	{
        original: "/images/home_slideshow/2.jpg"
    },
	{
        original: "/images/home_slideshow/3.jpg"
    },
    {
        original: "/images/home_slideshow/4.jpg"
    },
    {
        original: "/images/home_slideshow/5.jpg"
    },
    {
        original: "/images/home_slideshow/6.jpg"
    },
    {
        original: "/images/home_slideshow/7.jpg"
    },
	{
        original: "/images/home_slideshow/8.jpg"
    },
	{
        original: "/images/home_slideshow/9.jpg"
    },
]

export function Home() {
    return (
        <>
            <TopBar />
            <div id="homepage">
                <ImageGallery items={images} 
					lazyLoad={true} 
					showThumbnails={false} 
					showFullscreenButton={false} 
					showPlayButton={false} 
					autoPlay={true} 
					showBullets={true} 
					slideInterval={10000}
    />
            </div>
            <Footer />
        </>

    )
}