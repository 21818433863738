import './App.css';
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { TopBar } from "./components/TopBar/TopBar";
import { Home } from "./pages/Home/Home";
import { About } from "./pages/About/About";
import { Products } from "./pages/Products/Products";
import { Team } from "./pages/Team/Team";
import { Projects } from "./pages/Projects/Projects";
import { News } from "./pages/News/News";
import { Recruitment } from "./pages/Recruitment/Recruitment";
import { Youtube } from "./pages/Youtube/Youtube";

function App() {


  return (
    <div className="App">
      <Router basename={''}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/recruitment" element={<Recruitment />} />
          <Route exact path="/Youtube" element={<Youtube />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
