import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Youtube.css";
import Table from 'react-bootstrap/Table';
import { Footer } from "../../components/Footer/Footer";
import { TopBar } from "../../components/TopBar/TopBar";

export function Youtube() {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;



    return (
        <>	
			<TopBar currentPage="Youtube" />
            <div className="container py-5">
                <div className="col-sm-12 py-3">
                    <center><h1>Youtube Video</h1></center>
                    <p id="YTIntro" className={isMobile ? "py-4" : "py-4 px-5"}></p>
                    <br />
                    <h1 id="YTTitle" >SilentUP<sup>®</sup></h1>
                    <br />

                    <Table responsive borderless>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="YTTitle1" >
                                    <br />
                                    Silence your construction site! Retractable noise barrier helps!
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/2mIKB1BIJxg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                            <td valign="bottom" >
                                <h2 id="YTTitle2" >
                                    <br />
                                   SilentUP Retractable Barrier Product Introduction Video
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/tFc8ax55a74" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="YTTitle3" >
                                    <br />
                                    RISE Final Breakthrough Pitch - SilentUP®
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/oBqlwdZCl4A" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                            <td valign="bottom" >
                                <h2 id="YTTitle4" >
                                    <br />
                                    SilentUP<sup>®</sup> Retractable Noise Barrier - Wind Load Relieving Mechanism
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/RZcihX1tcUE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="YTTitle5" >
                                    <br />
                                    5.5m high SilentUP<sup>®</sup> Retractable Noise Barrier on-site installation
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/u83nA1emqQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                            <td valign="bottom" >
                                <h2 id="YTTitle6" >
                                    <br />
                                    5m SilentUP<sup>®</sup> noise barrier installation demonstration
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/7IviDT__gzU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="YTTitle7" >
                                    <br />
                                    Mobile Noise Barrier in Hong Kong
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/vA2hGXEO2mU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                            <td valign="bottom" >
                                <h2 id="YTTitle8" >
                                    <br />
                                    SilentUP<sup>®</sup> Noise Barrier Installation Guide
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/EOqvtzif91c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                        </tr>
                        <tr>
                            <td valign="bottom" >
                                <h2 id="YTTitle9" >
                                    <br />
                                    SilentUP<sup>®</sup> shaft cover
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/YSx__9ftM9o" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>
                        </tr>
                    </Table>

                    <h1 id="YTTitle20" >SilentCUBE<sup>®</sup></h1>
                    <br />
                            <td valign="bottom" >
                                <h2 id="YTTitle21" >
                                    <br />
                                    SilentCUBE noise enclosure installation demonstration
                                </h2>
                                <iframe width="272" height="153" src="https://www.youtube.com/embed/5mQ-0CrG8vo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </td>

                </div >                
            </div >       
			
            <Footer />
        </>

    )
}