import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from 'react-bootstrap/Card'
import Button from "react-bootstrap/Button"
import { Footer } from "../../components/Footer/Footer";
import { TopBar } from "../../components/TopBar/TopBar";
// import feedbackLogo from "../../images/add.png"
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";


const awards = [
    {
        name: "23rd Annual Safety, Health and Environmental Awards Convention, LTA in Singapore 2021",
        src: "/images/awards/LTA.png",
        description: "SilentUP is implemented in construction site of Penta Ocean Construction Co Ltd – Bachy Soletanche Singapore Pte Ltd Joint Venture, and championing 23rd Annual Safety, Health and Environmental Awards Convention by LTA in Singapore.   \n\n"
    },
    {
        name: "Most commonly applied Pre-approved Technologies among SME, CITF Advanced Technological Solution 2021",
        src: "/images/awards/CITF.png",
        description: "SilentUP® retractable noise barrier was implemented in Hong Kong construction sites since 2015 to improve construction productivities and reduce noise impact to nearby resident. Its merits have been recognized by Construction Institution Council (CIC) and entered preapproved list under Advanced Construction Technologies (ACT) of Construction Innovation and Technology Fund (CITF) in 2019.  SilentUP is also granted as ”Most commonly applied Pre-approved Technologies among SME” as Advanced Technological Solutions in CITF digest issue 9 in 2021. \n\n"
    },
    {
        name: "Breakthrough Hong Kong Competition, RISE Conference 2016",
        src: "/images/awards/RISE.png",
        description: "RISE Conference 2016 is one of the biggest startups events in Hong Kong.  Acoustics Innovation (AI), as one of the top 20 startups reached the Finals of Breakthrough Hong Kong, presented in front of world’s leading investors.  \n\nSilentUP® is well appreciated by the investor panels and awarded the 1st runner up in Breakthrough Hong Kong Competition, RISE Conference 2016.  \n\n"
    },
    {
        name: "Tech Incubation Fund",
        src: "/images/awards/TIF.png",
        description: "Acoustics Innovation (AI) was awarded the Tech Incubation Fund (TIF) by the Hong Kong Polytechnic University and Hong Kong Science Park in May 2015. The team presented SilentUP® Retractable Noise Barrier in its final pitch to the judging panel who showed great appreciation for the product.\n\nAI was granted incubation to Science Park as the award and a sum of HK$100,000 was also awarded by the Hong Kong Innovation and Technology Commission to continue R&D activities.\n\n"
    },
    {
        name: "InterNoise",
        src: "/images/awards/i-ince.png",
        description: "Acoustics Innovation published a paper on SilentUP®, titled \"Lightweight Noise Barrier\" for InterNoise 2014. It was selected by the International Institute of Noise Control Engineering (I-INCE) as the top 26 papers published by young professionals from all over the globe. Yasir Naveed, the then graduate engineer, was invited to Melbourne, Australia, to attend the InterNoise conference and present the paper. He was given the Young Professionals Grant from I-INCE, InterNoise 2014 Scholarships Committee and the Melbourne Convention Bureau.\n\nThis is the first time this award came to Hong Kong. We are proud to be the pioneers!\n\n"
    },
    {
        name: "IMechE Speak Out for Engineering",
        src: "/images/awards/SOFE.png",
        description: "Speak out For Engineering (SOFE) is the annual presentation competition organized by the Institute of Mechanical Engineers (IMechE) to nurture the public speaking skills of young mechanical engineers. In 2013, Acoustics Innovation staff Yasir Naveed presented the technologies of Multi-Directional Tuned Mass Damper (MDTMD). He won the Hong Kong local SOFE and represented Hong Kong in the international competition with 9 other countries including UK, USA, China, Netherlands, Singapore, India and more.\n\nTo our delight, we won the International SOFE as well! Yasir was named the Champion Speaker.\n\n"
    },
]

function NewlineText(props) {
    const text = props.text;
    return text.split('\n').map(str => <p>{str}</p>);
}

export function About() {
    const [email, setEmail] = useState("");
    const [cname, setCname] = useState("");
    const [comment, setComment] = useState("");
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    function addMsg(event) {
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': "application/x-www-form-urlencoded"
            },
            body: JSON.stringify({ 'email': email, 'name': cname, 'message': comment })
        }
        fetch("/php/contact.php", requestOptions)
            .then(res => res.json())
            .then(res => console.log(res))
    }

    const expandContent = (ind) => {
        document.getElementById("text" + ind.toString()).style.display = "block";
        document.getElementById("downicon" + ind.toString()).style.display = "none";
        document.getElementById("upicon" + ind.toString()).style.display = "block";
    }

    const collapseContent = (ind) => {
        document.getElementById("text" + ind.toString()).style.display = "none";
        document.getElementById("downicon" + ind.toString()).style.display = "block";
        document.getElementById("upicon" + ind.toString()).style.display = "none";
    }

    function validateForm() {
        return email.length > 0 && comment.length > 0 && cname.length > 0;
    }

    return (
        <>
            <TopBar currentPage="about" />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-3 my-md-5" id="recruitTitle">
                        <center><h2 id="overview">Overview</h2></center>
                    </div>
                    <div className="col-sm-12 col-md-9 my-md-5" id="recruitText">
                        Acoustics Innovation Limited (AI) is the R&D division of Wilson Acoustics Limited (WAL). We provide tools to fix innovative problems.
                        <br /><br />AI is the tech incubatee in Hong Kong Science Park with a team of 7 engineers. We have invented the SilentUP® Retractable Noise Barrier for construction works that can be installed by people without any machines, SilentCube Retractable Noise Enclosure for roadworks, and Magnetic Tuned Mass Damper for sheet-piling noise reduction. In addition to selling and leasing developed products, AI also provides consulting services in developing noise control products and solutions to assist customers in obtaining awards and patents.
                        <br /><br />Please visit our YouTube channel at <a href="https://www.youtube.com/channel/UC22DgQzxDnLUuSLl0Kjk1RQ">https://t.ly/j1YZk</a> for more details.
                    </div>
                </div>
                <div className="row col-sm-12 my-3">
                    <center><h1>Awards</h1></center>
                    <br />
                    <Row md={1} lg={2} className="g-4">
                        {
                            awards.map((award, ind) => (
                                <Col>
                                    <Card style={{ width: '85%' }} bg="white" className="mx-auto border-0">
                                        <Card.Img variant="top" src={award.src} style={{ height: '100%' }} />
                                        <Card.Body id="kxtry">
                                            <div id="noOp">
                                                <Card.Title>{award.name}</Card.Title>
                                                <Card.Text><center><CaretDownFill id={"downicon" + ind.toString()} size={32} className="iconDrop" onClick={() => expandContent(ind)} /></center><p id={"text" + ind.toString()} style={{ 'display': "none" }}>{award.description}</p><center><CaretUpFill style={{ 'display': "none" }} id={"upicon" + ind.toString()} size={32} className="iconDrop" onClick={() => collapseContent(ind)} /></center> </Card.Text>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                // <div id={ind} className="col-md-12 my-1">
                                //     <h2 style={{ "fontFamily": 'Fjalla One', "textAlign": "left" }}>{award.name}</h2>
                                //     <center> <img src={award.src} alt={ind} className="awards_center" /> </center>
                                //     <NewlineText text={award.description} />
                                // </div>
                            ))
                        }
                    </Row>
                </div>
                <div className="row col-sm-12 my-3" id="whereSection">
                    <center><h1>Where We Are</h1></center>
                    <br />
                    <div className="container">
                        <div className="row">
                            <section className="col-sm-12 col-md-5">
                                <strong>Acoustics Innovation</strong><br />
                                <abbr title="Phone">P:</abbr> <a href="tel:+85227022007">+852 2702 2007</a> <br />
                                <abbr title="Fax">F:</abbr> <a href="tel:+85235655895">+852 3565 5895</a> <br />
                                <abbr title="Email">E:</abbr> <a href="mailto:who@aihk.hk">who@aihk.hk</a> <br />
                                Unit 106, Block A, Shatin Industrial Centre, <br />
                                Yuen Shun Circuit, Shatin, N.T., <br />
                                Hong Kong. <br />
                                香港新界沙田源順圍5-7號 沙田工業中心A座106室<br />
                                <br />
                                <strong>Wilson Acoustics Limited</strong><br />
                                <abbr title="Phone">P:</abbr> <a href="tel:+85231881170">+852 3188 1170</a> <br />
                                <abbr title="Fax">F:</abbr> <a href="tel:+85234228117">+852 3422 8117</a> <br />
                                <abbr title="Email">E:</abbr> <a href="mailto:who@wal.hk">who@wal.hk</a> <br />
                                Unit 601, Block A, Shatin Industrial Centre, <br />
                                Yuen Shun Circuit, Shatin, N.T., <br />
                                Hong Kong. <br />
                                香港新界沙田源順圍5-7號 沙田工業中心A座601室<br />
                                <br />
                                <strong>Acoustics Innovation (UK) Limited</strong><br />
                                <abbr title="Phone">P:</abbr> <a href="tel:+4407398570282">+44 0 73985 70282</a> <br />
                                <abbr title="Email">E:</abbr> <a href="mailto:who@aihk.hk">who@aihk.hk</a> <br />
                                Unit 11, Park Royal Business Centre, <br />
                                9 - 17, Park Royal Road, <br />
                                London, <br />
                                NW10 7LQ, The United Kingdom. <br />
	    		    </section>
                            <section className="col-sm-12 col-md-7">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m8!1m3!1d1844.601359698728!2d114.204984!3d22.383714!3m2!1i1024!2i768!4f13.1!4m12!1i0!3e6!4m3!3m2!1d22.383098999999998!2d114.20418!4m5!1s0x34040645a839ca83%3A0xdb009ce95f3745e8!2z5o-S5qGF5p2G6KGX5rKZ55Sw5bel5qWt5Lit5b-DIOmmmea4rw!3m2!1d22.384186!2d114.205788!5e0!3m2!1sen!2s!4v1409301525095"></iframe>
                                <a href="https://www.google.com/maps/dir/22.383099,114.20418/%E6%8F%92%E6%A1%85%E6%9D%86%E8%A1%97%E6%B2%99%E7%94%B0%E5%B7%A5%E6%A5%AD%E4%B8%AD%E5%BF%83+%E9%A6%99%E6%B8%AF/@22.383714,114.204984,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x34040645a839ca83:0xdb009ce95f3745e8!2m2!1d114.205788!2d22.384186?hl=en" target="new">Open map in a new window</a>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d620.5812781573256!2d-0.2665072302847676!3d51.525596532627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487611ef38d42ea5%3A0x7436308677d83540!2s9-17%20Park%20Royal%20Rd%2C%20London%20NW10%207LQ!5e0!3m2!1sen!2suk!4v1705953586874!5m2!1sen!2suk"></iframe>
                                <a href="https://maps.app.goo.gl/beJoqCgCkEqE4xLg8" target="new">Open map in a new window</a>
	    		     </section>
                        </div>
                    </div>
                </div>
                <div className="row col-sm-12 my-3">
                    <center><h1>Contact Us</h1></center>
                    <br />
                    <div className="row">
                        <div className="col-sm-12 col-md-6" id={isMobile ? "" : "addSep"}>
                            <Form className="mx-3" onSubmit={addMsg}>
                                <Form.Group as={Row} className="mb-3" controlId="formName">
                                    <Form.Label column sm={2}>Name</Form.Label>
                                    <Col sm={10}><Form.Control type="text" placeholder="Joe Bloggs" value={cname} onChange={(e) => { setCname(e.target.value); }} /></Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formEmail">
                                    <Form.Label column sm={2}>Email</Form.Label>
                                    <Col sm={10}><Form.Control type="email" value={email} placeholder="joe.bloggs@email.com" onChange={(e) => { setEmail(e.target.value); }} /></Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="formMessage">
                                    <Form.Label column sm={2}>Message:</Form.Label>
                                    <Col sm={10}><Form.Control as="textarea" rows={3} placeholder=" Let us know what you think!" value={comment} onChange={(e) => { setComment(e.target.value); }} /></Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3 d-flex justify-content-between">
                                    <Col sm={{ span: 10, offset: 2 }}>
                                        <Button block type="submit" disabled={!validateForm()} >Leave your feedback</Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                        {/* <div className="vertical-row" /> */}
                        <div className="col-sm-12 col-md-6 " id="surveyLink">
                            <div className="d-flex flex-row">
                                {!isMobile ? <div className="p-2 mx-3">
                                    <img src="/images/add.png" alt="feedback" />
                                </div> : <></>}
                                <div className="p-2 mx-3">
                                    <p>Leave your <br />feedback <br /><a href="https://goo.gl/forms/fJkL9rogkk6NIX2l1" >http://aihk.hk/survey</a></p>
                                </div>
                            </div>



                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>

    )
}//TODO logic of addMsg()
